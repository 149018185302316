@charset "UTF-8";
/**
 * Fontface mixin
 * @param string $weight Font family
 * @param int    $weight Font weight
 * @param type   $style  Font Style
 * @return rule
 */
/**
 * PxGrotesk mixin
 * @param int  $weight Font weight
 * @param type $style  Font Style
 * @return rule
 */
/**
 * Input placeholder styles
 */
/**
 * Last child margin reset
 * @param property $property
 */
/**
 * Creates a set of options (extends the current selector)
 * @param list $combos List example $list : ("selector-added-class" : ( "property" value, "property" value ))
 */
/**
 * Padded content
 */
/**
 * Slick dots overrides
 * @param int  $size    Dots size
 * @param list $color   List containing initial, hover and selected colors
 * @param int  $spacing Space between dots
 */
/**
 * Slick arrows overrides
 * @param int  $size      Arrows size
 * @param list $color     List containing initial, hover and disabled colors
 * @param int  $translate Special adjustment measure (depending on column offset for the slick container)
 */
/**
 * Custom icons
 */
/**
 * Vertical align using position absolute
 */
/**
 * Gradients
 */
/**
 * Homepage
 */
body.home .container-fluid {
  max-width: 1440px;
  margin: 0 auto;
  background-color: white; }

@media (min-width: 768px) {
  body.home .header {
    position: relative; } }

body.home .header .nav {
  padding: 0.6em 0 2em;
  text-align: right; }
  @media (min-width: 768px) {
    body.home .header .nav {
      padding: 1.4em 0; } }
  @media (min-width: 992px) {
    body.home .header .nav {
      padding: 1em 0; } }
  body.home .header .nav ul {
    width: auto;
    list-style: none; }
    body.home .header .nav ul li {
      display: inline-block;
      margin-right: 1em; }
      body.home .header .nav ul li:last-child {
        margin-right: 0; }
      body.home .header .nav ul li a {
        transition: color 0.25s ease;
        font-family: "PxGrotesk";
        font-weight: 300;
        font-style: normal;
        color: #363636;
        text-decoration: none; }
        @media (min-width: 992px) {
          body.home .header .nav ul li a {
            font-size: 0.85em; } }
        body.home .header .nav ul li a:hover {
          color: #030303; }
        body.home .header .nav ul li a i {
          display: inline-block;
          font: normal normal normal 14px/1 FontAwesome;
          font-size: inherit;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale; }
          body.home .header .nav ul li a i:before {
            content: ""; }
      body.home .header .nav ul li.lang {
        position: relative;
        padding: 0.5em; }
        body.home .header .nav ul li.lang .dropdown-container {
          z-index: 1;
          position: absolute;
          visibility: hidden;
          top: 115%;
          right: 0;
          width: 6em;
          background-color: #cccccc;
          text-transform: none;
          text-align: left; }
          body.home .header .nav ul li.lang .dropdown-container:before {
            position: absolute;
            right: 0.7em;
            margin-left: -0.6em;
            border-width: 0.6em;
            border-style: solid;
            content: "";
            bottom: 100%;
            border-color: transparent transparent #cccccc transparent; }
          body.home .header .nav ul li.lang .dropdown-container li {
            display: block;
            margin-right: 0; }
            body.home .header .nav ul li.lang .dropdown-container li a {
              display: block;
              padding: 0.8em 0.7em;
              transition: background-color 0.25s ease;
              color: white;
              text-decoration: none; }
              body.home .header .nav ul li.lang .dropdown-container li a:hover {
                background-color: #b3b2b2; }
              body.home .header .nav ul li.lang .dropdown-container li a span {
                display: inline-block;
                opacity: 0.6;
                margin-right: 0.4em;
                width: 1.8em;
                font-weight: 500; }

body.home .header .search img {
  display: block;
  max-width: 45%;
  margin: 0 auto 2.2em;
  transform: translateX(1em); }
  @media (min-width: 768px) {
    body.home .header .search img {
      width: 12em;
      max-width: none;
      margin: 0 0 2.4em 0.3em;
      transform: none; } }
  @media (min-width: 992px) {
    body.home .header .search img {
      margin: 0 0 3em 0.3em; } }

body.home .header .search h1 {
  margin-bottom: 1.8em;
  font-family: "PxGrotesk";
  font-weight: 500;
  font-style: normal;
  font-size: 2em;
  text-align: center; }
  @media (min-width: 768px) {
    body.home .header .search h1 {
      margin-bottom: 1.6em;
      font-size: 2.2em;
      text-align: left; } }
  @media (min-width: 992px) {
    body.home .header .search h1 {
      font-size: 2.6em; } }
  body.home .header .search h1 span {
    font-weight: 300; }
  @media (min-width: 992px) {
    body.home .header .search h1 {
      margin-bottom: 1.6em; } }

body.home .header .search .search-container .field-container {
  padding: 0.25em;
  border: 1px solid #cecece;
  border-right: none; }
  body.home .header .search .search-container .field-container .selector {
    cursor: pointer;
    background-color: #a4a4a4;
    border: 0.1em solid white;
    font-family: "PxGrotesk";
    font-weight: 300;
    font-style: normal;
    text-align: center;
    text-transform: uppercase;
    color: white; }
    @media (max-width: 767.98px) {
      body.home .header .search .search-container .field-container .selector {
        font-size: 1.4em; } }
    @media (min-width: 576px) {
      body.home .header .search .search-container .field-container .selector {
        font-size: 1.4em; } }
    @media (min-width: 992px) {
      body.home .header .search .search-container .field-container .selector {
        font-size: 1.2em; } }
    body.home .header .search .search-container .field-container .selector span:last-child {
      display: none; }
    body.home .header .search .search-container .field-container .selector i {
      display: inline-block;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale; }
      body.home .header .search .search-container .field-container .selector i:before {
        content: ""; }
    @media (min-width: 992px) {
      body.home .header .search .search-container .field-container .selector span:first-child {
        display: none; }
      body.home .header .search .search-container .field-container .selector span:last-child {
        display: block;
        text-transform: none;
        text-align: left; }
      body.home .header .search .search-container .field-container .selector .dropdown-trigger {
        transition: background-color 0.25s ease; }
        body.home .header .search .search-container .field-container .selector .dropdown-trigger:hover {
          background-color: #cc4100; } }
    body.home .header .search .search-container .field-container .selector.dropdown-open .dropdown-trigger,
    body.home .header .search .search-container .field-container .selector.dropdown-open .dropdown-trigger:hover {
      background-color: #363636; }
    body.home .header .search .search-container .field-container .selector .dropdown-container {
      z-index: 1;
      position: absolute;
      visibility: hidden;
      top: 100%;
      left: 0;
      width: 12em;
      background-color: #363636;
      text-transform: none;
      text-align: left; }
      @media (min-width: 576px) {
        body.home .header .search .search-container .field-container .selector .dropdown-container {
          top: 150%; }
          body.home .header .search .search-container .field-container .selector .dropdown-container:before {
            position: absolute;
            left: 1.8em;
            margin-left: -0.6em;
            border-width: 0.6em;
            border-style: solid;
            content: "";
            bottom: 100%;
            border-color: transparent transparent #363636 transparent; } }
      @media (min-width: 992px) {
        body.home .header .search .search-container .field-container .selector .dropdown-container {
          font-size: 0.9em; } }
      body.home .header .search .search-container .field-container .selector .dropdown-container li a {
        display: block;
        padding: 0.8em 0.7em;
        transition: background-color 0.25s ease;
        color: white;
        text-decoration: none; }
        body.home .header .search .search-container .field-container .selector .dropdown-container li a:hover {
          background-color: #1d1c1c; }
        body.home .header .search .search-container .field-container .selector .dropdown-container li a span {
          display: inline-block;
          opacity: 0.6;
          margin-right: 0.4em;
          width: 2.2em;
          font-weight: 500; }
      @media (max-width: 767.98px) {
        body.home .header .search .search-container .field-container .selector .dropdown-container li {
          font-size: 0.8em; } }
  body.home .header .search .search-container .field-container .field {
    padding: 0.8em 0.8em; }
    body.home .header .search .search-container .field-container .field input {
      width: 100%;
      flex: 0 0 100%;
      max-width: 100%;
      border: none;
      border-radius: 0;
      font-family: "PxGrotesk";
      font-weight: 300;
      font-style: normal;
      font-size: 0.9em; }
      @media (max-width: 767.98px) {
        body.home .header .search .search-container .field-container .field input {
          font-size: 1em; } }
      @media (min-width: 768px) {
        body.home .header .search .search-container .field-container .field input {
          font-size: 1.2em; } }
      @media (min-width: 992px) {
        body.home .header .search .search-container .field-container .field input {
          font-size: 1.2em; } }
    body.home .header .search .search-container .field-container .field input::-webkit-input-placeholder {
      color: #7f7f7f; }
    body.home .header .search .search-container .field-container .field input::-moz-placeholder {
      color: #7f7f7f; }
    body.home .header .search .search-container .field-container .field input:-ms-input-placeholder {
      color: #7f7f7f; }
    body.home .header .search .search-container .field-container .field input:-moz-placeholder {
      color: #7f7f7f; }
    @media (min-width: 768px) {
      body.home .header .search .search-container .field-container .field {
        padding: 0.8em; } }
    @media (min-width: 992px) {
      body.home .header .search .search-container .field-container .field {
        padding: 1em 0.8em; } }

body.home .header .search .search-container .button {
  display: flex; }
  body.home .header .search .search-container .button input {
    cursor: pointer;
    flex: 1;
    width: 100%;
    margin: 0;
    transition: background-color 0.25s ease;
    border: none;
    background-color: #ff5100;
    border-radius: 0;
    font-family: "PxGrotesk";
    font-weight: 300;
    font-style: normal;
    color: white;
    text-transform: uppercase; }
    @media (min-width: 768px) {
      body.home .header .search .search-container .button input {
        font-size: 1.2em; } }
    @media (min-width: 992px) {
      body.home .header .search .search-container .button input {
        font-size: 1.2em; } }
    @media (max-width: 767.98px) {
      body.home .header .search .search-container .button input {
        text-indent: -9999px; } }
    body.home .header .search .search-container .button input:hover {
      background-color: #504f4f; }
  @media (max-width: 767.98px) {
    body.home .header .search .search-container .button {
      position: relative; }
      body.home .header .search .search-container .button i {
        position: absolute;
        top: 45%;
        transform: translateY(-50%);
        width: 100%;
        text-align: center;
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 1.6em !important;
        color: white; }
        body.home .header .search .search-container .button i:before {
          content: ""; } }

body.home .header .skyline {
  padding-bottom: 1em;
  background-color: #e2fdff; }
  @media (max-width: 991.98px) {
    body.home .header .skyline {
      margin-top: 4em; } }
  @media (min-width: 992px) {
    body.home .header .skyline img {
      width: 100% !important; } }

@media (min-width: 992px) {
  body.home .header.resized .search {
    position: absolute;
    top: 10%;
    z-index: 1; }
  body.home .header.resized .skyline {
    position: absolute;
    bottom: 0; } }

body.home .highlighted {
  padding: 2em 0 0;
  background-color: #e2fdff; }
  @media (min-width: 768px) {
    body.home .highlighted {
      padding: 2.8em 0; } }
  @media (min-width: 992px) {
    body.home .highlighted {
      padding: 4.2em 0; } }
  body.home .highlighted h3 {
    margin-bottom: 0;
    color: #3da6eb; }
    @media (min-width: 768px) {
      body.home .highlighted h3 {
        text-align: center; } }
  body.home .highlighted p {
    font-size: 1em; }
    body.home .highlighted p a {
      font-family: "PxGrotesk";
      font-weight: 300;
      font-style: normal;
      text-decoration: none;
      text-transform: uppercase;
      color: #363636; }
    @media (min-width: 768px) {
      body.home .highlighted p {
        font-size: 0.85em;
        text-align: center; } }
  body.home .highlighted .list {
    margin-top: 1em; }
    body.home .highlighted .list.slick-dotted.slick-slider {
      margin-bottom: 0;
      padding-bottom: 3.6em; }
    body.home .highlighted .list .slick-dots {
      bottom: 1.2em; }
      body.home .highlighted .list .slick-dots li {
        width: 1.2em;
        height: 1.2em;
        margin: 0 1.2em 0 0;
        padding: 0; }
        body.home .highlighted .list .slick-dots li:last-child {
          margin-right: 0; }
        body.home .highlighted .list .slick-dots li button {
          outline: none;
          width: 100%;
          height: 100%;
          transition: background-color 0.25s ease;
          border-radius: 50%;
          background-color: #befbff; }
          body.home .highlighted .list .slick-dots li button:hover {
            background-color: #85f7ff; }
          body.home .highlighted .list .slick-dots li button:before {
            content: none; }
        body.home .highlighted .list .slick-dots li.slick-active button {
          background-color: #3da6eb; }
    body.home .highlighted .list .slick-list {
      z-index: 1; }
    body.home .highlighted .list .slick-prev,
    body.home .highlighted .list .slick-next {
      z-index: 1;
      width: 2em;
      height: 4em;
      transition: background-color 0.25s ease;
      background-color: #3da6eb;
      font-size: 1em; }
      body.home .highlighted .list .slick-prev:hover,
      body.home .highlighted .list .slick-next:hover {
        background-color: #178fde; }
      body.home .highlighted .list .slick-prev:before,
      body.home .highlighted .list .slick-next:before {
        transition: opacity 0.25s ease;
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 1.5em;
        line-height: 0; }
      body.home .highlighted .list .slick-prev.slick-disabled, body.home .highlighted .list .slick-prev.slick-disabled:hover,
      body.home .highlighted .list .slick-next.slick-disabled,
      body.home .highlighted .list .slick-next.slick-disabled:hover {
        background-color: #befbff; }
        body.home .highlighted .list .slick-prev.slick-disabled:before, body.home .highlighted .list .slick-prev.slick-disabled:hover:before,
        body.home .highlighted .list .slick-next.slick-disabled:before,
        body.home .highlighted .list .slick-next.slick-disabled:hover:before {
          opacity: 1; }
    body.home .highlighted .list .slick-prev {
      left: -2em;
      border-top-left-radius: 2em;
      border-bottom-left-radius: 2em;
      transform: translate(33.3333333333%, -2em); }
      body.home .highlighted .list .slick-prev:before {
        content: ""; }
    body.home .highlighted .list .slick-next {
      right: -2em;
      border-top-right-radius: 2em;
      border-bottom-right-radius: 2em;
      transform: translate(-33.3333333333%, -2em); }
      body.home .highlighted .list .slick-next:before {
        content: ""; }

body.home .blog {
  padding: 2em 0 0;
  background-color: #3da6eb; }
  @media (min-width: 768px) {
    body.home .blog {
      padding: 2.8em 0; } }
  @media (min-width: 992px) {
    body.home .blog {
      padding: 4.2em 0; } }
  body.home .blog h5, body.home .blog h3, body.home .blog p {
    color: white; }
  body.home .blog h6 {
    color: #0c68a5; }
  body.home .blog .form {
    margin-bottom: 1.45em; }
    body.home .blog .form input {
      width: 100%;
      padding: 0.8em 1em;
      border: none;
      font-family: "PxGrotesk";
      font-weight: 300;
      font-style: normal;
      font-size: 0.9em; }
      @media (max-width: 767.98px) {
        body.home .blog .form input {
          font-size: 1em; } }
      body.home .blog .form input[type="submit"] {
        cursor: pointer;
        transition: background-color 0.25s ease;
        border: none;
        background-color: #0c68a5;
        border-radius: 0;
        color: white;
        text-transform: uppercase; }
        body.home .blog .form input[type="submit"]:hover {
          background-color: #094a75; }
    body.home .blog .form input::-webkit-input-placeholder {
      color: #7f7f7f; }
    body.home .blog .form input::-moz-placeholder {
      color: #7f7f7f; }
    body.home .blog .form input:-ms-input-placeholder {
      color: #7f7f7f; }
    body.home .blog .form input:-moz-placeholder {
      color: #7f7f7f; }
  body.home .blog ul.tags {
    list-style: none; }
    body.home .blog ul.tags li {
      display: inline-block;
      margin: 0 1em 0.5em 0; }
      body.home .blog ul.tags li:last-child {
        margin-right: 0; }
      body.home .blog ul.tags li i {
        margin-right: 0.2em;
        display: inline-block;
        font: normal normal normal 14px/1 'CustomIcons';
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-transform: none;
        font-size: 1em; }
        body.home .blog ul.tags li i:before {
          content: "a"; }
      @media (min-width: 992px) {
        body.home .blog ul.tags li {
          font-size: 0.9em; } }
      body.home .blog ul.tags li a {
        transition: color 0.25s ease;
        color: white;
        text-decoration: none; }
        body.home .blog ul.tags li a:hover {
          color: #c8e6f9; }
  body.home .blog .entries {
    padding: 2em 0 0; }
    @media (min-width: 768px) {
      body.home .blog .entries {
        padding: 1.4em 0;
        background-color: #f7f7f7; } }
    body.home .blog .entries .blog-list.slick-dotted.slick-slider {
      margin-bottom: 0;
      padding-bottom: 3.6em; }
    body.home .blog .entries .blog-list .slick-dots {
      bottom: 1.2em; }
      body.home .blog .entries .blog-list .slick-dots li {
        width: 1.2em;
        height: 1.2em;
        margin: 0 1.2em 0 0;
        padding: 0; }
        body.home .blog .entries .blog-list .slick-dots li:last-child {
          margin-right: 0; }
        body.home .blog .entries .blog-list .slick-dots li button {
          outline: none;
          width: 100%;
          height: 100%;
          transition: background-color 0.25s ease;
          border-radius: 50%;
          background-color: #befbff; }
          body.home .blog .entries .blog-list .slick-dots li button:hover {
            background-color: #85f7ff; }
          body.home .blog .entries .blog-list .slick-dots li button:before {
            content: none; }
        body.home .blog .entries .blog-list .slick-dots li.slick-active button {
          background-color: white; }
    body.home .blog .entries .blog-list .slick-list {
      z-index: 1; }
    body.home .blog .entries .blog-list .slick-prev,
    body.home .blog .entries .blog-list .slick-next {
      z-index: 1;
      width: 2em;
      height: 4em;
      transition: background-color 0.25s ease;
      background-color: #3da6eb;
      font-size: 1em; }
      body.home .blog .entries .blog-list .slick-prev:hover,
      body.home .blog .entries .blog-list .slick-next:hover {
        background-color: #178fde; }
      body.home .blog .entries .blog-list .slick-prev:before,
      body.home .blog .entries .blog-list .slick-next:before {
        transition: opacity 0.25s ease;
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 1.5em;
        line-height: 0; }
      body.home .blog .entries .blog-list .slick-prev.slick-disabled, body.home .blog .entries .blog-list .slick-prev.slick-disabled:hover,
      body.home .blog .entries .blog-list .slick-next.slick-disabled,
      body.home .blog .entries .blog-list .slick-next.slick-disabled:hover {
        background-color: #befbff; }
        body.home .blog .entries .blog-list .slick-prev.slick-disabled:before, body.home .blog .entries .blog-list .slick-prev.slick-disabled:hover:before,
        body.home .blog .entries .blog-list .slick-next.slick-disabled:before,
        body.home .blog .entries .blog-list .slick-next.slick-disabled:hover:before {
          opacity: 1; }
    body.home .blog .entries .blog-list .slick-prev {
      left: -2em;
      border-top-left-radius: 2em;
      border-bottom-left-radius: 2em;
      transform: translate(42.5531914894%, -2em); }
      body.home .blog .entries .blog-list .slick-prev:before {
        content: ""; }
    body.home .blog .entries .blog-list .slick-next {
      right: -2em;
      border-top-right-radius: 2em;
      border-bottom-right-radius: 2em;
      transform: translate(-42.5531914894%, -2em); }
      body.home .blog .entries .blog-list .slick-next:before {
        content: ""; }

body.home .ebooks {
  padding: 2em 0 0;
  background-color: white; }
  @media (min-width: 768px) {
    body.home .ebooks {
      padding: 2.8em 0; } }
  @media (min-width: 992px) {
    body.home .ebooks {
      padding: 4.2em 0; } }
  @media (min-width: 768px) {
    body.home .ebooks {
      padding-bottom: 0; } }
  body.home .ebooks h2 {
    text-align: center; }
  body.home .ebooks .list {
    margin-top: 2em; }
    body.home .ebooks .list .item > .row {
      height: 100%; }
      body.home .ebooks .list .item > .row > div {
        background-color: #f7f7f7; }
        body.home .ebooks .list .item > .row > div > .row {
          height: 100%;
          flex-direction: column;
          flex-wrap: nowrap; }
    body.home .ebooks .list .item .image {
      flex-grow: 0;
      flex-shrink: 1;
      flex-basis: auto; }
    body.home .ebooks .list .item .info {
      display: flex;
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: auto;
      padding: 0.4em 0; }
      body.home .ebooks .list .item .info > .row {
        width: 100%; }
      body.home .ebooks .list .item .info h4 {
        font-family: "PxGrotesk";
        font-weight: 500;
        font-style: normal;
        margin-bottom: 0.3em;
        font-size: 1.3em;
        text-align: center; }
        @media (min-width: 768px) {
          body.home .ebooks .list .item .info h4 {
            font-size: 1.1em; } }
        @media (min-width: 992px) {
          body.home .ebooks .list .item .info h4 {
            font-size: 1.15em; } }
        body.home .ebooks .list .item .info h4 a {
          text-decoration: none;
          color: #363636; }
        body.home .ebooks .list .item .info h4 a {
          text-decoration: none;
          color: #363636; }
      body.home .ebooks .list .item .info p {
        font-family: "PxGrotesk";
        font-weight: 300;
        font-style: normal;
        font-size: 1.1em;
        line-height: 1.4em;
        color: #363636;
        text-align: center; }
        @media (min-width: 768px) {
          body.home .ebooks .list .item .info p {
            font-size: 0.95em; } }
        @media (min-width: 992px) {
          body.home .ebooks .list .item .info p {
            font-size: 0.85em; } }
      body.home .ebooks .list .item .info [class^="col-"] {
        padding: 0.6em 0.3em; }
        body.home .ebooks .list .item .info [class^="col-"]:last-child {
          padding-bottom: 1em; }
    body.home .ebooks .list.slick-dotted.slick-slider {
      margin-bottom: 0;
      padding-bottom: 3.6em; }
    body.home .ebooks .list .slick-dots {
      bottom: 1.2em; }
      body.home .ebooks .list .slick-dots li {
        width: 1.2em;
        height: 1.2em;
        margin: 0 1.2em 0 0;
        padding: 0; }
        body.home .ebooks .list .slick-dots li:last-child {
          margin-right: 0; }
        body.home .ebooks .list .slick-dots li button {
          outline: none;
          width: 100%;
          height: 100%;
          transition: background-color 0.25s ease;
          border-radius: 50%;
          background-color: #cccccc; }
          body.home .ebooks .list .slick-dots li button:hover {
            background-color: #7f7f7f; }
          body.home .ebooks .list .slick-dots li button:before {
            content: none; }
        body.home .ebooks .list .slick-dots li.slick-active button {
          background-color: #363636; }
  body.home .ebooks .slick-slide > div {
    height: 100%; }
  body.home .ebooks .slick-initialized .item {
    display: flex;
    height: 100%; }

body.home .promo {
  position: relative; }
  body.home .promo .square {
    display: flex; }
    body.home .promo .square > .row {
      flex: 1; }
      body.home .promo .square > .row > div {
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: auto;
        align-self: center;
        padding: 1.6em 0;
        background-color: white; }
        @media (min-width: 768px) {
          body.home .promo .square > .row > div {
            padding: 2em 0; } }
    @media (min-width: 768px) {
      body.home .promo .square {
        position: absolute;
        height: 100%;
        right: 4%; } }
    body.home .promo .square h6 {
      color: #cccccc; }
    body.home .promo .square h3 {
      color: #00a8b4; }

body.home .register {
  padding: 2em 0 0;
  padding-bottom: 0 !important;
  background-color: #85f7ff; }
  @media (min-width: 768px) {
    body.home .register {
      padding: 2.8em 0; } }
  @media (min-width: 992px) {
    body.home .register {
      padding: 4.2em 0; } }
  body.home .register h2, body.home .register h3 {
    text-align: center;
    color: #018790; }
  body.home .register .list {
    margin: 2em 0 3em; }
    body.home .register .list .item .image .rounded {
      position: relative;
      width: 11em;
      height: 11em;
      margin: 0 auto 1.4em;
      background-color: white;
      border-radius: 50%; }
      @media (min-width: 768px) {
        body.home .register .list .item .image .rounded {
          width: 13em;
          height: 13em;
          margin-bottom: 1.8em; } }
      body.home .register .list .item .image .rounded img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 6em;
        margin: 0 auto; }
        @media (min-width: 768px) {
          body.home .register .list .item .image .rounded img {
            height: 7em; } }
    @media (min-width: 768px) {
      body.home .register .list .item .info h3 {
        font-size: 1.6em; } }
    body.home .register .list.slick-dotted.slick-slider {
      margin-bottom: 0;
      padding-bottom: 3.6em; }
    body.home .register .list .slick-dots {
      bottom: 1.2em; }
      body.home .register .list .slick-dots li {
        width: 1.2em;
        height: 1.2em;
        margin: 0 1.2em 0 0;
        padding: 0; }
        body.home .register .list .slick-dots li:last-child {
          margin-right: 0; }
        body.home .register .list .slick-dots li button {
          outline: none;
          width: 100%;
          height: 100%;
          transition: background-color 0.25s ease;
          border-radius: 50%;
          background-color: #e2fdff; }
          body.home .register .list .slick-dots li button:hover {
            background-color: #00a8b4; }
          body.home .register .list .slick-dots li button:before {
            content: none; }
        body.home .register .list .slick-dots li.slick-active button {
          background-color: #10474b; }
  body.home .register .cta-container {
    padding: 2em 0;
    background-color: #6ddbed; }
    body.home .register .cta-container .text-container {
      padding: 0 1.4em;
      text-align: center; }
      @media (max-width: 991.98px) {
        body.home .register .cta-container .text-container {
          padding-top: 1.2em;
          padding-bottom: 1.2em; } }
      body.home .register .cta-container .text-container.half-size {
        padding: 0 0.7em; }
    body.home .register .cta-container .button.google, body.home .register .cta-container .button.facebook {
      position: relative;
      padding-left: 4.2em;
      color: white; }
      body.home .register .cta-container .button.google i, body.home .register .cta-container .button.facebook i {
        position: absolute;
        top: 0;
        left: 0;
        width: 2.4em;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.15);
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 130%;
        line-height: 195%;
        text-align: center; }
    body.home .register .cta-container .button.google {
      background-color: #c6181e; }
      body.home .register .cta-container .button.google i:before {
        content: ""; }
    body.home .register .cta-container .button.facebook {
      background-color: #0c68a5; }
      body.home .register .cta-container .button.facebook i:before {
        content: ""; }

body.home .about {
  background-color: #3da6eb; }
  @media (min-width: 768px) {
    body.home .about {
      padding: 2.8em 0; } }
  @media (min-width: 992px) {
    body.home .about {
      padding: 3.4em 0; } }
  @media (min-width: 992px) {
    body.home .about {
      background-size: auto 100%;
      background-position: right center;
      background-repeat: no-repeat; } }
  body.home .about .form {
    background-color: white; }
    body.home .about .form > .row > div {
      padding: 2em 0; }
      @media (min-width: 768px) {
        body.home .about .form > .row > div {
          padding: 2.8em 0; } }
      @media (min-width: 992px) {
        body.home .about .form > .row > div {
          padding: 3.4em 0; } }
    body.home .about .form h5 {
      color: #7f7f7f; }
    body.home .about .form h3 {
      color: #0c68a5; }
    body.home .about .form input[type="text"] {
      margin-bottom: 0.7em !important; }
    body.home .about .form input[type="text"],
    body.home .about .form input[type="email"] {
      width: 100%;
      margin: 0;
      padding: 0.8em 1em;
      border: 1px solid #cccccc;
      font-family: "PxGrotesk";
      font-weight: 300;
      font-style: normal;
      font-size: 0.9em; }
      @media (max-width: 767.98px) {
        body.home .about .form input[type="text"],
        body.home .about .form input[type="email"] {
          font-size: 1em; } }
    body.home .about .form input::-webkit-input-placeholder {
      color: #7f7f7f; }
    body.home .about .form input::-moz-placeholder {
      color: #7f7f7f; }
    body.home .about .form input:-ms-input-placeholder {
      color: #7f7f7f; }
    body.home .about .form input:-moz-placeholder {
      color: #7f7f7f; }
    body.home .about .form input[type="submit"] {
      cursor: pointer;
      flex: 1;
      width: 100%;
      margin: 0;
      transition: background-color 0.25s ease;
      border: none;
      background-color: #0c68a5;
      border-radius: 0;
      font-family: "PxGrotesk";
      font-weight: 300;
      font-style: normal;
      font-size: 0.9em;
      color: white;
      text-transform: uppercase; }
      @media (min-width: 768px) {
        body.home .about .form input[type="submit"] {
          font-size: 1em; } }
      body.home .about .form input[type="submit"]:hover {
        background-color: #094a75; }
  body.home .about .team {
    padding: 2em 0; }
    @media (min-width: 768px) {
      body.home .about .team {
        padding: 0; } }
    body.home .about .team h5 {
      color: #f4fafe; }
    body.home .about .team h3, body.home .about .team p {
      color: white; }
